@font-face {
  font-family: "YouShei-Black";
  src: url("../assets/font/YouSheBiaoTiHei-2.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "PangMen-Black";
  src: url("../assets/font/pangMengZhengDao-3.0.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
body ::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
body ::-webkit-scrollbar-track {
  background: none;
  border-radius: 3px;
}
body ::-webkit-scrollbar-thumb {
  background-color: rgba(2, 49, 100, 0.88);
}
body ::-webkit-scrollbar-thumb:hover {
  background-color: #023164;
}
html,
body {
  font-size: 14px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.uav-mtop15 {
  margin-top: 15px;
}
.uav-mbottom15 {
  margin-bottom: 15px;
}
#root {
  width: 100vw;
  height: 100vh;
  min-width: 1280px;
}
.ant-layout-sider {
  z-index: 1;
}
.ant-layout,
.ant-layout-sider {
  background: none !important;
}
.ant-table-cell-row-hover {
  background: none !important;
}
.uav-page {
  margin: 15px 0;
}
.uav-page-row {
  margin: 0 15px;
}
.ant-form label {
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0px;
  font-feature-settings: "kern" on;
  color: #333333;
}
.ant-dropdown .ant-dropdown-arrow:before {
  background: #51a7fc !important;
}
.ant-modal-title {
  font-size: 20px !important;
  font-weight: bold !important;
  line-height: 24px !important;
  letter-spacing: 0px !important;
  color: #333333 !important;
  filter: blur(0px) !important;
}
.ant-modal {
  padding-bottom: 0 !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: none !important;
}
.ant-layout-content {
  position: relative;
}
.ant-table-thead > tr > td:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  width: 0px !important;
}
.ant-upload-drag .ant-upload-btn {
  height: 252px !important;
}
.ant-tree .ant-tree-switcher {
  width: 0 !important;
  margin: 0 !important;
}
.ant-checkbox .ant-checkbox-inner .ant-tooltip .ant-tooltip-inner {
  color: #333333 !important;
}
.ant-tooltip .ant-tooltip-arrow:before {
  background: #a2a2a2 !important;
}
.cesium-viewer-animationContainer,
.cesium-viewer-timelineContainer {
  z-index: 10;
}
.ant-tooltip.ant-tooltip-geekblue .ant-tooltip-inner {
  background-color: #ffffff !important;
  color: #333333 !important;
}
.ant-tree {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: 0px !important;
  color: #333333 !important;
}
.ant-layout-header {
  height: 100vh !important;
}
.ant-dropdown .ant-dropdown-arrow::after {
  background: #a2a2a2 !important;
}
.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #ffffff !important;
}
.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td {
  text-align: center;
}

.tabs {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  p {
    // margin: 0;
    // padding: 0;
    // font-size: 1.8rem;
    // font-family: "PangMen-Black", sans-serif;
    // line-height: 52px;
  }

  .tab-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
    gap: 16px;
    list-style: none;
    height: 225px;
    position: relative;
    li {
      width: 50px;
      height: 44px;
    }
  }
  .selectList {
    position: absolute;
    left: 45px;
    top: -40px;
    width: 160px;
    height: 241px;
    background-image: url("../../../assets/images/systemList.png");
    background-size: 100%;
    background-repeat: no-repeat;
    .list {
      list-style-type: none;
      .list-item {
        width: 100%;
        text-align: center;
        height: 30px;
        line-height: 30px;
        font-size: 16px;
        letter-spacing: 0em;
        font-variation-settings: "opsz" auto;
        font-feature-settings: "kern" on;
        color: #333333;
      }
      .list-item:hover {
        color: #1594ff;
      }
    }
  }
  .tab-item div {
    width: 124px;
  }
  .tab-item:hover .textWrap {
    display: block;
  }
  .tab-item {
    width: 32px;
    height: 32px;
    opacity: 1;
    cursor: pointer;
    position: relative;
    background-size: 100%;
    background-repeat: no-repeat;
    margin: 6px 0px;
    &.active {
      // background: #b0e0fe;
      // background-size: 100%;
      // opacity: 0.6;
    }
  }
}

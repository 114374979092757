.container-lauyout {
  height: 100%;
  // overflow-y: auto;
  position: relative;
  // overflow-x: hidden;
  // background-color: red;
}

.uav-gl-header-container {
  width: 50px;
  height: 100vh;
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0;

  // height: 89px;
  // padding: unset;
  background: none !important;
  z-index: 1;
}

.uav-gl-content-container {
  height: calc(100% - 89px);
  width: 100%;
  overflow-x: hidden;
  background: none;
  position: relative;
  // z-index: 1;
}

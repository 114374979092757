.login-container {
  height: 100%;
  display: grid;
  place-items: center;
  align-content: stretch;
  align-items: start;
  justify-items: center;
  // background-image:  url(../../assets//login//login-bg.webp);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .login-header {
    width: 100%;
    .header {
      width: 100%;
      height: 100px;
      background-image: url(../../assets/login/login-head.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      h1 {
        all: unset;
        line-height: 100px;
        text-shadow: 0px 0px 9px #159aff;
        font-size: 40px;
        font-weight: 400;
        letter-spacing: 2px;
        color: #f2fbff;
        font-family: "YouShei-Black", sans-serif;
        margin-left: 40px;
      }
    }
  }

  .login-from {
    width: 696px;
    display: grid;
    justify-content: center;
    align-items: center;
    height: 386px;
    background-image: url(../../assets/login/loginFormBg.png);
    background-repeat: no-repeat;
    background-size: 100%;
    margin-bottom: 5%;
    form {
      .ant-input-affix-wrapper {
        background: none;
      }
      .ant-input-prefix .anticon svg {
        width: 1.5em;
        height: 1.5em;
      }
      width: 50%;
      #userNameId {
        opacity: 1;
        border-radius: 7.79px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0px 0px 0px 19.33px;
        background: none;
        color: #ffffff;
        &::placeholder {
          color: #ffffff;
          font-size: 12px;
        }
      }
      #passWordId {
        opacity: 1;
        border-radius: 7.79px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0px 0px 0px 19.33px;
        background: none;
        color: #ffffff;

        &::placeholder {
          color: #ffffff;
          font-size: 12px;
        }
      }
      .ant-checkbox-checked .ant-checkbox-inner:after {
        border-color: rgb(9, 23, 42) !important;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background: #1594ff;
      }
      .ant-checkbox-inner {
        border: 1px solid rgb(21, 148, 255);
        background: none;
      }
    }
  }
}

.header-subcontainer {
  width: 50px;
  height: 100vh;
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0;
  background: #ffffff;
  //   h1 {
  //     all: unset;
  //     line-height: 89px;
  //     height: 89px;
  //     text-shadow: 0px 0px 9px rgba(21, 154, 255, 1);
  //     font-size: 46px;
  //     font-weight: 400;
  //     letter-spacing: 2px;
  //     color: rgba(242, 251, 255, 1);
  //     text-align: left;
  //     vertical-align: top;
  //     font-family: "YouShei-Black", sans-serif;
  //   }

  .header-middle {
    height: 70px;
    display: flex;
    align-items: flex-end;
  }

  //   .header-right-box {
  //     height: 70px;
  //     display: flex;

  //     .header-right-show {
  //       all: unset;
  //       display: flex;
  //       flex-direction: column;
  //       height: inherit;
  //       align-items: center;

  //       p {
  //         margin: 0;
  //         padding: 0;
  //         line-height: 24px;
  //         font-size: 1rem;
  //         font-weight: 400;
  //         color: rgba(21, 148, 255, 1);
  //         font-family: "思源黑体", sans-serif;
  //       }
  //     }

  .quit-login {
    width: 100%;
    height: 50px;
    text-align: center;
    color: #333333;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
  }
  //   }
}

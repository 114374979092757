@font-face {
  font-family: "YouShei-Black";
  src: url("../assets/font/YouSheBiaoTiHei-2.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PangMen-Black";
  src: url("../assets/font/pangMengZhengDao-3.0.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

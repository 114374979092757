#cesiumContainer {
  height: 100vh;
  width: 100vw;
  position: absolute;
  .buttonList {
    z-index: 999;
    background: #f3f9ff;
    position: absolute;
    bottom: 40px;
    right: 10px;
    width: 60px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    .button {
      width: 100px;
      height: 100px;
    }
  }
}
